




import { Component, Prop, Vue } from 'vue-property-decorator'
import { CaseListProps } from '@/components/case-list/types'
import SidebarComponent from '@/components/case-list/SidebarComponent.vue'

@Component({
  components: { SidebarComponent }
})
export default class CaseListApp extends Vue {
  @Prop() private caseListProps: CaseListProps | undefined;
}
