



































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SidebarComponent extends Vue {
  @Prop() vastUrl!: string
}
